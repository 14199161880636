import Blog from './blog';

// no idea how to import and re export it from ./blog
export const query = graphql`
query($lang: String = "en-gb") {
  prismic {
    allAuthorss(lang: $lang) {
      edges {
        node {
          firstname
          lastname
          image
          _linkType
        }
      }
    }
  }

  ...BlogFragment
}
`;

export default Blog;
